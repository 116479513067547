<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { troubleshoot } from "@/config/api/troubleshoot";

/**
 * Basic-table component
 */
export default {
  page: {
    title: "Troubleshoot",
    meta: [{ name: "description", content: "Troubleshoot issues with data" }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Troubleshoot",
      vehicleInfo: {
        status: 'unknown'
      },
      driverLocation: {
        status: 'unknown'
      },
      oldLocation: {
        status: 'unknown'
      },
      campaigns_expired: {
        status: 'unknown'
      },

    };
  },
  methods: {
    check_vehicle_status() {
      const api = troubleshoot.vehicle_check;
      this.generateAPI(api)
        .then((res) => {
          this.vehicleInfo.status = res.data.vehicle_list == 0 ? "Up" : "Fix"
        })
        ;
    },
    fix_vehicle_status() {
      const api = troubleshoot.vehicle_fix;
      this.generateAPI(api)
        .then(() => {
          this.check_vehicle_status();
        })
        ;
    },
    check_vehicle_location() {
      const api = troubleshoot.driver_location_check;
      this.generateAPI(api)
        .then((res) => {
          this.driverLocation.status = res.data.user_type == 0 ? "Up" : "Fix"
        });
    },
    fix_vehicle_location() {
      const api = troubleshoot.driver_location_fix;
      this.generateAPI(api)
        .then(() => {
          this.check_vehicle_location();
        });
    },
    driver_old_location_fix() {
      const api = troubleshoot.driver_old_location_fix;
      this.generateAPI(api)
        .then(() => {
          this.old_location_check();
        });
    },
    old_location_check() {
      const api = troubleshoot.driver_old_location_check;
      this.generateAPI(api)
        .then((res) => {
          this.oldLocation.status = res.data.user_type == 0 ? "Up" : "Fix"
        });
    },
    campaigns_expired_issues_check() {
      const api = troubleshoot.campaigns_expired_issues_check;
      this.generateAPI(api)
        .then((res) => {
          this.campaigns_expired.status = res.data.campaigns.length == 0 ? "Up" : "Fix"
        });
    },
    campaigns_expired_issues_fix() {
      const api = troubleshoot.campaigns_expired_issues_fix;
      this.generateAPI(api)
        .then(() => {
         this.campaigns_expired_issues_check();
        });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />



    <div class="row">

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Troubleshoot the system</h4>

            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Module</th>

                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Vehicle Info</td>
                    <td @click="check_vehicle_status" v-if="vehicleInfo.status == 'unknown'">Check</td>
                    <td @click="fix_vehicle_status" v-else>{{ vehicleInfo.status }}</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>User Missing Vehicles</td>
                    <td @click="check_vehicle_location" v-if="driverLocation.status == 'unknown'">Check</td>
                    <td @click="fix_vehicle_location" v-else>{{ driverLocation.status }}</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Old Location Check</td>
                    <td @click="old_location_check" v-if="oldLocation.status == 'unknown'">Check</td>
                    <td @click="driver_old_location_fix" v-else>{{ oldLocation.status }}</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Campaign Expire Check</td>
                    <td @click="campaigns_expired_issues_check" v-if="campaigns_expired.status == 'unknown'">Check</td>
                    <td @click="campaigns_expired_issues_fix" v-else>{{ campaigns_expired.status }}</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>